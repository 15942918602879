@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Allura&family=Cinzel+Decorative:wght@400;700;900&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');


h2,h3{
    font-family:"Cinzel Decorative";
}

p {
    font-family: "Jost";
}

h4 {
    font-family: "allura";
}

.article-image {
    filter: blur(20px);
    animation: clearImage 4s ease forwards;
  }
  
  .art {
    filter: blur(10px);
    animation: clearImage 2s ease forwards;
  }
  
  @keyframes clearImage {
    from {
      filter: blur(20px);
    }
    to {
      filter: blur(0);
    }
  }