.hero-container {
  position: relative;
}

.hero-container img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    filter: blur(10px);
    transition: opacity 1s ease, filter 2s ease; /* Add transition for filter */
  }
  
  .hero-container img.active {
    opacity: 1;
    filter: blur(0);
  }

  .hero-container img.opacity-transition {
    transition: opacity 1s ease;
    background-color: black;
  }

  